function handleBrowserNotSupported(notSupportedBrowsers) { // NOSONAR
  var titleIcon = "angular.svg";
  var titleIconAlt = "angular-app";
  var defaultLang = "hu";

  console.log('checking browser support');

  var messages = {
    'hu': {
      'title': 'Nem támogatott böngésző verziót használ!',
      'content': 'Internetes böngészőjének jelenlegi verzióját nem támogatja ez a weboldal.',
      'content2': 'Kérjük, hogy a weboldal biztonságos és stabil működése érdekében válasszon az alábbi böngészők közül.',
      'content3': 'Köszönjük.'
    },
    'en': {
      'title': 'You are using a not supported browser!',
      'content': 'Your browser is not supported on this page.',
      'content2': 'Please, to ensure the safe and stable operation of the website choose from browsers below.',
      'content3': 'Thank you!'
    }
  };


  if (browserIsNotSupported()) {
    // get used language
    var savedLang = localStorage.getItem('lang');
    var navigatorLang = (navigator.language || navigator.userLanguage);
    var userLang = navigatorLang ? navigatorLang.slice(0, 2) : "";
    var currentLang = savedLang ? savedLang : userLang;

    // get content for language
    var curContent = messages[currentLang] ? messages[currentLang] : messages[defaultLang];

    // render browser not supported html
    document.getElementsByTagName('app-root')[0].innerHTML = getHtml(curContent);
  }

  /* notSupportedBrowsers = ['ie10': true, 'ie11': true, 'edge': true, 'safari': true]; */
  function browserIsNotSupported() {
    var userAgentLowerCase = window.navigator.userAgent.toLowerCase();

    if (notSupportedBrowsers['ie10'] && agentContains('msie ')) {
      return true;
    }

    if (notSupportedBrowsers['ie11'] && agentContains('trident/')) {
      return true;
    }

    if (notSupportedBrowsers['edge'] && agentContains('edge/')) {
      return true;
    }

    if (notSupportedBrowsers['safari'] && agentContains('safari') && !agentContains('chrome')) {
      return true;
    }

    function agentContains(string) {
      return userAgentLowerCase.indexOf(string) !== -1;
    }

    return false;
  }

  function getHtml(content) {
    var html =
      "<div class=\"not-supported-container\">\n" +
      "    <div class=\"not-supported-img-backdrop\">\n" +
      "        <div class=\"not-supported-title-icon\">" +
      "           <img src='assets/image/" + titleIcon + "' alt='" + titleIconAlt + "'>" +
      "        </div>\n" +
      "        <h1 class=\"thin-title not-supported-title\">\n" +
      "           " + content['title'] + "\n" +
      "        </h1>\n" +
      "        <div class=\"not-supported-card\">\n" +
      "            <div class=\"not-supported-card-body\">\n" +
      "                <div class=\"not-supported-message\">\n" +
      "                     " + content['content'] + "<br/>\n" +
      "                     " + content['content2'] + "<br/>\n" +
      "                     " + content['content3'] + "\n" +
      "                </div>\n" +
      "            </div>\n" +
      "        </div>\n" +
      "        <div class=\"not-supported-browser-list\">\n" +
      "            <div class=\"not-supported-browser-icon\">\n" +
      "                <img src=\"assets/image/browser/browser_firefox.png\" alt=\"firefox\"/>\n" +
      "            </div>\n" +
      "            <div class=\"not-supported-browser-icon\">\n" +
      "                <img src=\"assets/image/browser/browser_chrome.svg\" alt=\"chrome\"/>\n" +
      "            </div>\n" +
      "            <div class=\"not-supported-browser-icon\">\n" +
      "                <img src=\"assets/image/browser/browser_opera.svg\" alt=\"opera\"/>\n" +
      "            </div>\n";

    if (!notSupportedBrowsers['ie11']) {
      var iconIE = notSupportedBrowsers['ie10'] ? 'browser_ie_11' : 'browser_ie';
      html += addBrowserImage(iconIE);
    }

    if (!notSupportedBrowsers['edge']) {
      html += addBrowserImage('browser_edge');
    }

    if (!notSupportedBrowsers['safari']) {
      html += addBrowserImage('browser_safari');
    }

    html += "</div></div></div>";

    function addBrowserImage(icon) {
      return "<div class=\"not-supported-browser-icon\">\n" +
        "    <img src=\"assets/image/browser/" + icon + ".svg\" alt=\"" + icon + "\"/>\n" +
        "</div>\n";
    }

    return html;
  }
}

handleBrowserNotSupported({ 'ie10': true, 'ie11': true });
// Test values;
// IE 10
// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
// IE 11
// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
// Edge 12 (Spartan)
// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
// Edge 13
// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
